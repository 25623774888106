<!--suppress HtmlUnknownTarget -->
<template>
  <div class="container-web-app">
    <ArrowBack :path-name="toPageUrl($options.name)" />
    <br />
    <div class="d-flex headline font-weight-bold justify-center">
      <v-img max-width="50" src="assets/img/svg/steamcmd-gui.svg" />
      <div class="align-self-center ml-3">SteamCMD GUI</div>
    </div>

    <div class="text-center mt-10 mb-5">
      A desktop GUI application used to
      <Url :dark-mode="isDarkModeEnabled" href="https://developer.valvesoftware.com/wiki/Dedicated_Servers_List">
        setup game servers
      </Url>
      that can be downloaded via SteamCMD.
    </div>

    Screenshot:
    <v-tabs v-model="tabPlatform">
      <v-tab>
        <img
          :src="`assets/img/svg/${platformMain === 'mac' ? platformDefault : platformMain}.svg`"
          alt=""
          class="tab__icon"
        />
      </v-tab>
      <v-tab>
        <img :src="`assets/img/svg/${platformAlt}.svg`" alt="" class="tab__icon" />
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabPlatform" :dark="isDarkModeEnabled" :light="!isDarkModeEnabled" class="mt-3 mb-10">
      <v-tab-item>
        <Url :dark-mode="isDarkModeEnabled" :href="urls[platformMain === 'mac' ? platformDefault : platformMain]">
          <v-img
            :max-width="getMaxWidth(platformMain)"
            :src="`assets/img/screenshots/steamcmd-gui-${platformMain === 'mac' ? platformDefault : platformMain}.png`"
          />
        </Url>
      </v-tab-item>
      <v-tab-item>
        <Url :dark-mode="isDarkModeEnabled" :href="urls[platformAlt]">
          <v-img
            :max-width="getMaxWidth(platformAlt)"
            :src="`assets/img/screenshots/steamcmd-gui-${platformAlt}.png`"
          />
        </Url>
      </v-tab-item>
    </v-tabs-items>

    <div class="d-flex align-center flex-column">
      <div class="mb-5">
        Available for:
        <Url
          :dark-mode="isDarkModeEnabled"
          :href="urls[platformMain === 'mac' ? platformDefault : platformMain]"
          class="text-capitalize"
          >{{ platformMain === "mac" ? platformDefault : platformMain }}</Url
        >,
        <Url :dark-mode="isDarkModeEnabled" :href="urls[platformAlt]" class="text-capitalize">
          {{ platformAlt }}
        </Url>
      </div>

      <div>SteamCMD GUI isn't available for macOS, since SteamCMD doesn't support most game servers on macOS.</div>
    </div>
  </div>
</template>

<script>
import ArrowBack from "@/components/ArrowBack";
import { mapGetters } from "vuex";
import Url from "@/components/Url";

export default {
  name: "SteamcmdGui",
  components: { Url, ArrowBack },
  data() {
    return {
      tabPlatform: 0,
      urls: {
        windows: "http://zipvale.com/8xcC",
        linux: "http://zipvale.com/8xfV"
      }
    };
  },
  computed: {
    ...mapGetters(["isProductActive", "toPageUrl"]),
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.$options.name);
    },
    platformMain() {
      if (navigator.platform.match(/win/i)) {
        return "windows";
      }
      if (navigator.platform.includes("Linux")) {
        return "linux";
      }
      return "mac";
    },
    platformDefault() {
      return this.platformMain === "mac" ? "windows" : this.platformMain;
    },
    platformAlt() {
      switch (this.platformMain) {
        case "windows":
          return "linux";
        case "linux":
          return "windows";
        default:
          return "linux";
      }
    }
  },
  methods: {
    getMaxWidth(platform) {
      return platform === "windows" ? 646 : 650;
    }
  }
};
</script>

<style scoped>
.tab__icon {
  max-width: 35px;
}
</style>
